<script>
import { roundTo } from '@shared/services/Utils';
import utilsMixin from '@shared/mixins/utils';
import Trainings from '@app/services/API/Trainings';
import StatisticCard from '@shared/components/StatisticCard.vue';
import TrainingItemStatisticCard from '@app/components/TrainingItemStatisticCard.vue';

export default {
  mixins: [utilsMixin],
  components: { StatisticCard, TrainingItemStatisticCard },
  props: {
    training: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    isFetching: false,
    stats: null,
  }),
  computed: {
    nbCustomersStartedPercent() {
      return this.stats.nb_customers === 0 ? 0 : roundTo(
        (this.stats.nb_customers_started_training / this.stats.nb_customers) * 100,
      );
    },
    nbCustomersCompletedPercent() {
      return this.stats.nb_customers === 0 ? 0 : roundTo(
        (this.stats.nb_customers_completed_training / this.stats.nb_customers) * 100,
      );
    },
    avgCustomerDuration() {
      const divider = this.stats.nb_customers_started_training;
      return divider === 0 ? 0 : Math.round(
        this.stats.total_customer_durations / divider,
      );
    },
  },
  created() {
    if (this.training) {
      this.isFetching = true;
      Trainings.getGlobalStats(this.training.uuid)
        .then(({ data }) => (this.stats = data))
        .finally(() => (this.isFetching = false));
    }
  },
};
</script>

<template>
  <div>
    <b-skeleton v-if="isFetching" height="520" />
    <div v-else>
      <section class="mb-5">
        <h2 class="title is-5 mb-3">
          Statistiques globales
        </h2>
        <go-pro-wrapper>
          <ul class="columns is-multiline">
            <li class="column is-4">
              <StatisticCard class="h-full">
                <template #title>
                  <span v-t="`stats.nb_customers.title`" />
                </template>
                <template #tooltipContent>
                  <span v-t="`stats.nb_customers.tooltip`" />
                </template>
                <template #default>
                  {{ stats.nb_customers }}
                </template>
              </StatisticCard>
            </li>
            <li class="column is-4">
              <StatisticCard class="h-full">
                <template #title>
                  <span v-t="`stats.nb_customers_started_training.title`" />
                </template>
                <template #tooltipContent>
                  <span v-t="`stats.nb_customers_started_training.tooltip`" />
                </template>
                <template #default>
                  {{ nbCustomersStartedPercent }}%
                  ({{ stats.nb_customers_started_training }})
                </template>
              </StatisticCard>
            </li>
            <li class="column is-4">
              <StatisticCard class="h-full">
                <template #title>
                  <span v-t="`stats.nb_customers_completed_training.title`" />
                </template>
                <template #tooltipContent>
                  <span v-t="`stats.nb_customers_completed_training.tooltip`" />
                </template>
                <template #default>
                  {{ nbCustomersCompletedPercent }}%
                  ({{ stats.nb_customers_completed_training }})
                </template>
              </StatisticCard>
            </li>
            <li class="column is-4">
              <StatisticCard class="h-full">
                <template #title>
                  <span v-t="`stats.avg_customer_duration_training.title`" />
                </template>
                <template #tooltipContent>
                  <span v-t="`stats.avg_customer_duration_training.tooltip`" />
                </template>
                <template #default>
                  {{ avgCustomerDuration | formatSeconds }}
                </template>
              </StatisticCard>
            </li>
            <li class="column is-4">
              <StatisticCard class="h-full">
                <template #title>
                  <span v-t="`stats.total_revenue.title`" />
                </template>
                <template #tooltipContent>
                  <span v-t="`stats.total_revenue.tooltip`" />
                </template>
                <template #default>
                  {{ stats.total_revenue | formatPrice }}
                </template>
              </StatisticCard>
            </li>
            <li class="column is-4">
              <StatisticCard class="h-full">
                <template #title>
                  <span v-t="`stats.total_pending_revenue.title`" />
                </template>
                <template #tooltipContent>
                  <span v-t="`stats.total_pending_revenue.tooltip`" />
                </template>
                <template #default>
                  {{ stats.total_pending_revenue | formatPrice }}
                </template>
              </StatisticCard>
            </li>
          </ul>
        </go-pro-wrapper>
      </section>

      <section class="mb-5">
        <h2 class="title is-5 mb-3">
          Statistiques des contenus
        </h2>
        <go-pro-wrapper>
          <ul>
            <template v-for="item in stats.training_items">
              <li v-if="item.type == 'SECTION'" :key="`${item.type}-${item.id}`" class="box p-0">
                <div class="bbottom-grey p-3">
                  <h3 class="has-text-weight-bold is-size-7">
                    {{ item.name }}
                    <span v-if="item.draft" class="tag has-text-weight-normal ml-2 is-size-9">
                      <b-icon
                        class="mr-1 is-size-9"
                        icon="circle"
                        type="is-warning"
                      />
                      En brouillon
                    </span>
                  </h3>
                </div>
                <ul v-if="item.children">
                  <TrainingItemStatisticCard
                    v-for="child in item.children"
                    :key="child.id"
                    tag="li"
                    :nbCustomers="stats.nb_customers"
                    :item="child"
                    class="bbottom-grey-light"
                  />
                </ul>
                <div v-else class="p-3">
                  <p class="notification">
                    Pas encore de contenu dans ce chapitre.
                  </p>
                </div>
              </li>
              <TrainingItemStatisticCard
                v-else
                class="box"
                :key="`${item.type}-${item.id}`"
                tag="li"
                :nbCustomers="stats.nb_customers"
                :item="item"
              />
            </template>
            <div v-if="!stats.training_items.length">
              <p class="box">
                Pas encore de contenu dans cette formation.
              </p>
            </div>
          </ul>
        </go-pro-wrapper>
      </section>
    </div>
  </div>
</template>
