<script>
import theme from '@shared/assets/styles/theme';
import utilsMixin from '@shared/mixins/utils';
import Trainings from '@app/services/API/Trainings';
import MetricsWrapper from '@app/components/MetricsWrapper.vue';
import BaseChartJS from '@app/components/BaseChartJS.vue';

export default {
  mixins: [utilsMixin],
  components: { MetricsWrapper, BaseChartJS },
  props: {
    training: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    isFetching: true,
    stats: {
      range: [null, null],
      data: null,
    },
  }),
  computed: {
    chartData() {
      const { data } = this.stats;
      const color = theme.color.primary;

      return {
        customer: {
          labels: Object.keys(data.customer).map((v) => this.$moment(v).format('DD/MM/YY')),
          datasets: [{
            label: 'Apprenants ayant rejoint cette formation',
            data: Object.values(data.customer),
            fill: 'origin',
            backgroundColor: color,
            pointStyle: 'line',
          }],
        },
        revenue: {
          labels: Object.keys(data.revenue).map((v) => this.$moment(v).format('DD/MM/YY')),
          datasets: [{
            label: 'Revenus de cette formation',
            data: Object.values(data.revenue),
            fill: 'origin',
            backgroundColor: color,
            pointStyle: 'line',
          }],
        },
        sale: {
          labels: Object.keys(data.sale).map((v) => this.$moment(v).format('DD/MM/YY')),
          datasets: [{
            label: 'Nombre de ventes sur cette formation',
            data: Object.values(data.sale),
            fill: 'origin',
            backgroundColor: color,
            pointStyle: 'line',
          }],
        },
      };
    },
    chartOptions() {
      return {
        customer: {
          scales: {
            y: {
              beginAtZero: true,
              grace: '5%',
              ticks: {
                stepSize: 1,
              },
            },
          },
        },
        revenue: {
          scales: {
            y: {
              beginAtZero: true,
              grace: '5%',
              ticks: {
                stepSize: 10,
              },
            },
          },
        },
        sale: {
          scales: {
            y: {
              beginAtZero: true,
              grace: '5%',
              ticks: {
                stepSize: 1,
              },
            },
          },
        },
      };
    },
    valueSum() {
      return {
        customer: Object.values(this.stats.data.customer)
          .reduce((prev, curr) => (prev + curr), 0),
        revenue: Object.values(this.stats.data.revenue)
          .reduce((prev, curr) => (prev + curr), 0),
        sale: Object.values(this.stats.data.sale)
          .reduce((prev, curr) => (prev + curr), 0),
      };
    },
  },
  watch: {
    'stats.range': {
      handler() {
        let [begin, end] = this.stats.range;
        const tz = this.$moment().format('Z');

        begin = this.$moment(begin).startOf('day').utc().format('YYYY-MM-DD HH:mm:ss');
        end = this.$moment(end).endOf('day').utc().format('YYYY-MM-DD HH:mm:ss');

        this.isFetching = true;
        Trainings.getHistogramStats(this.training.uuid, { tz, begin, end })
          .then(({ data }) => (this.stats.data = data))
          .finally(() => (this.isFetching = false));
      },
    },
  },
};
</script>

<template>
  <MetricsWrapper v-model="stats.range" initialSelector="LAST_30_DAYS" :isLoading="isFetching">
    <b-skeleton v-if="isFetching" height="520" />
    <div v-else>
      <div class="box mw-768">
        <h3 class="title is-6">
          Apprenants
        </h3>
        <BaseChartJS
          type="line"
          :data="chartData.customer"
          :options="chartOptions.customer"
        />
        <p class="is-primary mt-5 has-text-centered">
          {{ valueSum.customer }} apprenants ont rejoint la formation sur cette période.
        </p>
      </div>

      <div class="box mw-768">
        <h3 class="title is-6">
          Ventes
        </h3>
        <BaseChartJS
          type="line"
          :data="chartData.sale"
          :options="chartOptions.sale"
        />
        <p class="is-primary mt-5 has-text-centered">
          Cette formation a généré {{ valueSum.sale }} ventes sur cette période.
        </p>
      </div>

      <div class="box mw-768">
        <h3 class="title is-6">
          Revenus
        </h3>
        <BaseChartJS
          type="line"
          :data="chartData.revenue"
          :options="chartOptions.revenue"
        />
        <p class="is-primary mt-5 has-text-centered">
          Cette formation a généré {{ valueSum.revenue | formatPrice }} sur cette période.
        </p>
      </div>
    </div>
  </MetricsWrapper>
</template>
