<script>
import { roundTo } from '@shared/services/Utils';
import utilsMixin from '@shared/mixins/utils';

export default {
  mixins: [utilsMixin],
  props: {
    tag: {
      type: [String, Object],
      default: 'article',
    },
    nbCustomers: {
      type: Number,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    nbCustomersStartedPercent() {
      return this.nbCustomers === 0 ? 0 : roundTo(
        (this.item.nb_customers_started_training_item / this.nbCustomers) * 100,
      );
    },
    nbCustomersCompletedPercent() {
      return this.nbCustomers === 0 ? 0 : roundTo(
        (this.item.nb_customers_completed_training_item / this.nbCustomers) * 100,
      );
    },
    avgCustomerDuration() {
      const divider = this.item.nb_customers_started_training_item;
      return divider === 0 ? 0 : Math.round(
        (this.item.total_customer_durations / divider),
      );
    },
    avgCustomerSuccessRate() {
      return this.item.total_quiz_submissions === 0 ? 0 : roundTo(
        (this.item.total_percentage_sum / this.item.total_quiz_submissions),
      );
    },
    hasScore() {
      return this.item.type === 'QUIZ';
    },
  },
};
</script>

<template>
  <component :is="tag" class="p-3">
    <div class="columns pr-1 is-vcentered">
      <div
        class="column is-flex is-align-items-center"
        :class="{'is-4': hasScore, 'is-6': !hasScore}"
      >
        <b-tooltip>
          <template #content>
            <span v-t="`training_items.types.${item.type}`" />
          </template>
          <figure
            class="image is-40x40 bradius-8 has-text-centered has-background-grey"
          >
            <b-icon
              class="valign-middle h-full"
              :icon="$constants.icons[item.type]"
            />
          </figure>
        </b-tooltip>
        <h3 class="mnw-0 is-size-8 ml-3">
          <b-tooltip class="mw-full" :label="item.name" multilined append-to-body>
            <div class="overflow-hidden toverflow-ellipsis">
              <span class="has-text-clipped">{{ item.name }}</span>
            </div>
          </b-tooltip>
          <br>
          <span v-if="item.draft" class="tag has-text-weight-normal is-size-9 mt-2">
            <b-icon
              class="mr-1 is-size-9"
              icon="circle"
              type="is-warning"
            />
            En brouillon
          </span>
        </h3>
      </div>
      <div v-if="item.type == 'QUIZ'" class="column is-2 has-text-right px-2 bleft-grey">
        <h4 class="title is-8 mb-2">
          <b-tooltip multilined position="is-left">
            <template #content>
              <span v-t="`stats.avg_customer_success_rate_training_item_quiz.tooltip`" />
            </template>
            <span v-t="`stats.avg_customer_success_rate_training_item_quiz.title`" />
          </b-tooltip>
        </h4>
        <p>
          {{ avgCustomerSuccessRate }}%
        </p>
      </div>
      <div class="column is-2 has-text-right px-2 bleft-grey">
        <h4 class="title is-8 mb-2">
          <b-tooltip multilined position="is-left">
            <template #content>
              <span v-t="`stats.nb_customers_started_training_item.tooltip`" />
            </template>
            <span v-t="`stats.nb_customers_started_training_item.title`" />
          </b-tooltip>
        </h4>
        <p>
          {{ nbCustomersStartedPercent }}%
          ({{ item.nb_customers_started_training_item }})
        </p>
      </div>
      <div class="column is-2 has-text-right px-2 bleft-grey">
        <h4 class="title is-8 mb-2">
          <b-tooltip multilined position="is-left">
            <template #content>
              <span v-t="`stats.nb_customers_completed_training_item.tooltip`" />
            </template>
            <span v-t="`stats.nb_customers_completed_training_item.title`" />
          </b-tooltip>
        </h4>
        <p>
          {{ nbCustomersCompletedPercent }}%
          ({{ item.nb_customers_completed_training_item }})
        </p>
      </div>
      <div class="column is-2 has-text-right px-2 bleft-grey">
        <h4 class="title is-8 mb-2">
          <b-tooltip multilined position="is-left">
            <template #content>
              <span v-t="`stats.avg_customer_duration_training_item.tooltip`" />
            </template>
            <span v-t="`stats.avg_customer_duration_training_item.title`" />
          </b-tooltip>
        </h4>
        <p>
          {{ avgCustomerDuration | formatSeconds }}
        </p>
      </div>
    </div>
  </component>
</template>
