<script>
import TrainingGraphs from '@app/components/TrainingGraphs.vue';
import TrainingGlobalStatistics from '@app/components/TrainingGlobalStatistics.vue';

export default {
  components: {
    TrainingGraphs,
    TrainingGlobalStatistics,
  },
  computed: {
    currentTraining() {
      return this.$store.state.trainings.current;
    },
  },
};
</script>

<template>
  <div>
    <AppToggleSection :isOpen="!$store.getters['auth/hasExpertPlan']">
      <template #title>
        Graphiques
        <AppFeatureBadge class="ml-3 is-size-5" position="is-right" planLock="EXPERT" />
      </template>
      <template #default>
        <GoProWrapper
          planLock="EXPERT"
          :hasSep="false"
          height="260px"
          :bg="require('@app/assets/img/chart_example.png')"
        >
          <TrainingGraphs v-if="$store.getters['auth/hasExpertPlan']" :training="currentTraining" />
        </GoProWrapper>
      </template>
    </AppToggleSection>
    <TrainingGlobalStatistics :training="currentTraining" />
  </div>
</template>
